import { useState } from "react"
import { Button, Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch } from "react-redux";

const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const token = props.token ? props.token : false
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const dispatch = useDispatch()

    const handleChange = (e) => {
        const { name, value } = e.target
        return setProveedor(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }

    const crearNuevo = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/ordenes/campos-personalizados`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/ordenes/campos-personalizados`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        return setNuevoProveedor(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }

    const showOptions = (options, setStateFunction) => {
        if(!options) return null
        if(!Array.isArray(options)) return null
        return <div className="mt-3 mb-3">
            {
                options.map((option, index) => {
                    return <div key={`field-${index}`}>
                        <Row>
                            <Col xs={9}>
                        <input className="form-control mb-3" placeholder="Escribe la opción para el selector" name="value" value={option.value} onChange={(e) => {
                            setStateFunction(prev => {
                                let actual = { ...prev }
                                actual.options[index].value = e.target.value
                                return { ...actual }
                            })
                        }} />
                        </Col>
                        <Col xs={3}>
                        <Button className="w-100" variant="outline-danger" onClick={() => {
                            setStateFunction(prev => {
                                let actual = { ...prev }
                                actual.options.splice(index, 1)
                                return { ...actual }
                            })
                        }} >Eliminar</Button>
                        </Col>
                    </Row>
                    </div>
                })
            }            

        </div>
    }

    const dynamicOptions = (stateToManage, setStateFunction) => {
        if(!stateToManage?.type) return null
        if(stateToManage.type !== 'select') return null

        return <div className="mb-3">
            <label className="form-control-label d-block">Opciones</label>
            <Button size="sm" variant="light" onClick={() => {
                setStateFunction(prev => {
                    let actual = { ...prev}
                    if(!actual.options) actual.options = []
                    actual.options.push({ value: '' })
                    return actual
                })
            }} >AGREGAR OPCIÓN</Button>
            {showOptions(stateToManage?.options, setStateFunction)}
        </div>
    }

    const formularioNuevo = () => {
        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear nuevo campo personalizado</h4>
                    <p>Los campos personalizados se utilizan para guardar información adicional a las órdenes.</p>
                </Col>
                <Col className="mb-3">
                    <label className="form-control-label d-block">Título</label>
                    <input className="form-control" name="nombre" value={nuevoProveedor.nombre} onChange={handleChangeNuevo} />
                </Col>
                <Col className="mb-3">
                    <label className="form-control-label d-block">Tipo de campo</label>
                    <select className="form-control" name="type" value={nuevoProveedor.type} onChange={handleChangeNuevo} >
                        <option value="">Texto o Número</option>
                        <option value="select">Selector</option>
                        <option value="date">Fecha</option>
                    </select>
                </Col>
                <Col md={12}>
                {dynamicOptions(nuevoProveedor, setNuevoProveedor)}
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/ordenes/campos-personalizados?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()
        return <Row>
        <Col md={12} className="mb-3">
            <h4 className="mb-0">{proveedor.nombre}</h4>
            {
                removiendo === true ? <Spinner animation="border" /> : <Button variant="link" className="text-danger p-0 d-block mb-3" onClick={()=>solicitarEliminar(proveedor._id)} >Eliminar</Button>
            }
            <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Esta es la forma en que debes escribirlo en el documento excel de importación o API</Tooltip>
                            }
                            >
                                <h5 style={{ backgroundColor: '#c7c7c7', borderRadius: 5, color: 'white', fontSize: 13, display: 'inline', padding: '3px 5px' }}><b style={{ color:'black' }}>SLUG:</b> { proveedor.nombre ? proveedor.nombre.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'').normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '' }</h5>
                          </OverlayTrigger>
        </Col>
        <Col  className="mb-3">
            <label className="form-control-label d-block">Título</label>
            <input className="form-control" name="nombre" defaultValue={proveedor.nombre} onChange={handleChange} />
        </Col>
        <Col className="mb-3">
                    <label className="form-control-label d-block">Tipo de campo</label>
                    <select className="form-control" name="type" value={proveedor.type} onChange={handleChange} >
                        <option value="">Texto o Número</option>
                        <option value="select">Selector</option>
                        <option value="date">Fecha</option>
                    </select>
                </Col>
        <Col md={12}>
            {dynamicOptions(proveedor, setProveedor)}
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col>
    </Row>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull