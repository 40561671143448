import { useState } from "react"

const { Button, Modal } = require("react-bootstrap")

const ModalPersonalizado = (props) => {
    const {
        component,
        title
    } = props
    const [ showModal, setShowModal ] = useState(false)
    const titulo = title ? title : "MÁS DATOS"

    return <div>
        <Button size="sm" variant="light" style={{ fontSize: 12 }} onClick={() => setShowModal(true)}>{titulo}</Button>
        <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {component}
            </Modal.Body>
        </Modal>
    </div>
}

export default ModalPersonalizado