export const rutas = {
    excel_formats: {
        slug: 'excel-formats'
    },
    custom_fields: {
        slug: 'custom-fields'
    },
    geocercas: {
        slug: 'geofencing'
    },
    integraciones: {
        slug: 'integraciones'
    },
    proveedores:{
        slug: 'proveedores'
    },
    estadisticas:{
        slug: 'estadisticas'
    },
    estadisticas_tracking_conductor:{
        slug: "estadisticas-tracking"
    },
    vehiculos:{
        slug: 'vehiculos'
    },
    vehiculos_nuevo:{
        slug: 'vehiculos/nuevo'
    },
    vehiculos_importar_excel:{
        slug: 'vehiculos/importar'
    },
    actividad:{
        slug: 'actividad'
    },
    rendimiento_proveedores:{
        slug: 'rendimiento-flota-externa'
    },
    operacion_en_curso:{
        slug: 'operacion-en-curso'
    },
    actividad_flota:{
        slug: 'actividad-flota'
    },
    actividad_estados:{
        slug: 'actividad-estados'
    },
    actividad_auditoria:{
        slug: 'actividad-auditoria'
    },
    usuarios:{
        slug: 'usuarios'
    },
    ordenes:{
        slug: 'ordenes'
    },
    ordenes_crear:{
        slug: 'ordenes/nuevo'
    },
    todos_envios:{
        slug: 'envios-todos'
    },
    rutas:{
        slug: 'rutas'
    },
    rutas_importar_excel:{
        slug: 'rutas/importar'
    },
    crear_orden:{
        slug: 'rutas/crear'
    },
    rutas_importar_avanzado:{
        slug: 'rutas/importar-avanzado'
    },
    planificador:{
        slug: 'planificador'
    },
    rutas_asignaciones:{
        slug: 'rutas-asignaciones'
    },
    estados_carga:{
        slug: 'estados-carga'
    },
    flujos_carga:{
        slug: 'flujos-carga'
    },
    avanzada:{
        slug: 'configuracion'
    },
    etiquetas:{
        slug: 'etiquetas'
    },
    bodega_usuarios:{
        slug: 'bodega-usuarios'
    },
    bodega_bodegas:{
        slug: 'bodegas'
    },
    bodega_expediciones:{
        slug: 'bodega-expediciones'
    },
    canales:{
        slug: 'canales'
    },
    mercadolibre_config:{
        slug: 'integraciones/mercadolibre/init'
    },
    apiless_detail:{
        slug: 'integraciones/apiless/details'
    },
    logistic_providers:{
        slug: 'proveedores/transporte'
    },
    logistic_providers_pricing:{
        slug: 'proveedores/tarifas'
    },
    logistic_providers_link:{
        slug: 'proveedores/enlace'
    },
    integraciones_funciones_crear:{
        slug: 'integraciones/funciones/new'
    },
    clientes:{
        slug: 'clientes'
    },
    pricing:{
        slug: 'pricing'
    },
    pricing_lote_nuevo:{
        slug: 'pricing/lote/nuevo'
    },
    pricing_lotes:{
        slug: 'pricing/lote'
    },
    pricing_lotes_clients:{
        slug: 'pricing/lote/client'
    },
    facturacion: {
        slug: 'billing'
    },
    landing: {
        actividad: {
            slug: 'product'
        }
    },
    zones:{
        slug: "zonas"
    },
    alerts: {
        slug: "alertas"
    },
    map_alerts: {
        slug: "mapa-alertas"
    },
    destinatarios: {
        slug: "destinatarios"
    },
    destinatarios_bodegas_general: {
        slug: "destinatarios/bodegas/general"
    },
    destinatarios_contactos_general: {
        slug: "destinatarios/contactos/general"
    },
    destinatarios_importar_excel: {
        slug: "destinatarios/importar"
    },
    destinatarios_bodegas_importar_excel: {
        slug: "destinatarios/bodegas/importar"
    },
    destinatarios_contactos_importar_excel: {
        slug: "destinatarios/contactos/importar"
    },
    share_route: {
        slug: "share/routes"
    },
    report_tracking: {
        slug: "report/tracking"
    },
    listas_whatsapp: {
        slug: "listas/whatsapp"
    },
    instrucciones: {
        slug: "instrucciones-app-conductor"
    },
    facturacion_cliente: {
        slug: 'billing-client'
    },
    productos: {
        slug: "productos"
    },
    productos_importar_excel:{
        slug: 'productos/importar'
    },
    productos_actualizar_excel:{
        slug: 'productos/actualizar'
    },
    centinela:{
        slug: 'centinela'
    },
    recolecciones: {
        slug: "recolecciones"
    },
    payments: {
        slug: "payments"
    },
    payments_result: {
        slug: "payments/result/newpayment"
    },
    dashboard_courriers: {
        slug: "courriers/dashboard"
    },
    centinela_nuevo: {
        slug: "centinela/new"
    },
    centinela_rules: {
        slug: "centinela/rules"
    },
    envios: {
        slug: "envios"
    },
    tickets_public_access: {
        slug: "atention-center/tickets"
    },
    tickets: {
        slug: "tickets"
    },
    tickets_business: {
        slug: "tickets-provider"
    },
    receipts: {
        slug: "receipt"
    },
    ruteo_simple: {
        slug: "ruteo/simple"
    },
    recibos: {
        slug: "receipts"
    },
    ruteo_avanzado: {
        slug: "ruteo/avanzado"
    },
    tickets_pending: {
        slug: "tickets/pendientes"
    },
    ordenes_gestionadas: {
        slug: "ordenes/gestionadas"
    },
    auditoria_conductores: {
        slug: "auditoria-app"
    },
    documents: {
        slug: "document"
    },
    notificaciones_crear: {
        title: "Crear notificaciones",
        slug: "notificaciones/crear"
    },
    whatsapp: {
        slug: "whatsapp"
    },
    notificaciones: {
        slug: "notificaciones"
    },
    account: {
        slug: "account"
    },
    protocolos: {
        slug: "protocolos"
    },
    bodegas_contactos: {
        slug: "bodegas-contactos"
    },
    configuracion_courriers: {
        slug: "configuracion-courriers"
    },
    horarios_logistica: {
        slug: "horarios-logistica"
    },
    capacidades_especiales: {
        slug: "capacidades-especiales"
    },
    inventarios: {
        slug: "inventarios"
    },
    integra_web: {
        slug: 'integra-tu-web'
    },
}