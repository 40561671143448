import Structure from "../Structure"
import { useEffect, useState } from "react";
import { rutas } from "../../lib/routes/routes";
import { Link } from "react-router-dom";
import { main_data } from "../LineaTiempo/datos";
import { urlapi } from "../../lib/backend/data";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { FaChevronRight, FaRegEnvelope, FaRegHandPointer, FaRegTrashAlt, FaWhatsapp } from "react-icons/fa";
import { HiCursorClick } from "react-icons/hi";
import { Badge, Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { cerrarSesion } from "../../redux/actions/session";
import { AiFillCheckCircle } from 'react-icons/ai'
import { toast } from "react-toastify";
import TiposServicioSelector from "../../subComponents/ordenes/tipos_servicio/selector";
import { FALSE } from "sass";
import { createTheme, Step, StepLabel, Stepper, ThemeProvider } from "@mui/material";
const theme = createTheme({ })

const LineaTiempoNotificaciones = (props) => {
    const {
        showCreateButton,
        filter_serice_type,
        showImportButton,
        display_full,
        condicion,
    } = props
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion ? condicion : {})
    const [ loadingEstados, setLoadingEstados ] = useState(true)
    const [ loadingLineasTiempo, setLoadingLineasTiempo ] = useState(false)
    const [ tiposServicio, setTiposServicio ] = useState([])
    const [ tipoServicioSeleccionado, setTipoServicioSeleccionado ] = useState(null)
    const [ estados, setEstados ] = useState([])
    const [ lineaTiempoSeleccionada, setLineaTiempoSeleccionada ] = useState(null)
    const [ showModal, setShowModal ] = useState(false)
    const [ savingLineaTiempo, setSavingLineaTiempo ] = useState(false)
    const [ lineasTiempo, setLineasTiempo ] = useState([])
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const modulo = main_data.module

    useEffect(() => {
        obtenerEstados()
        obtenerLineasTiempo()
    }, [])

    const obtenerEstados = async () => {
        setLoadingEstados(true)
        return fetch(`${urlapi}/${modulo}/details`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res.estados) !== false){
                setEstados(res.estados)
            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEstados(false)
        })
    }

    const actualizarLineaTiempo = async (linea_tiempo) => {
        setSavingLineaTiempo(true)
        return fetch(`${urlapi}/${modulo}/lineas-tiempo`,{
            method:'PUT',
            body: JSON.stringify({...linea_tiempo, tipo_servicio: tipoServicioSeleccionado?._id }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){

            }
            return setSavingLineaTiempo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setSavingLineaTiempo(false)
        })
    }

    const obtenerLineasTiempo = async () => {
        setLoadingLineasTiempo(true)
        return fetch(`${urlapi}/${modulo}/lineas-tiempo`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setLineasTiempo(res)
                console.log(res)
            }
            return setLoadingLineasTiempo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingLineasTiempo(false)
        })
    }

    const seleccionarTipoServicio = (data) => {
        console.log(data)
        const i = lineasTiempo.findIndex(item => item.tipo_servicio === data?._id)
        console.log("i", i)
        if( i > -1){
            setLineaTiempoSeleccionada(lineasTiempo[i])
        } else {
            setLineaTiempoSeleccionada({ hitos: [] })
        }
        setTipoServicioSeleccionado(data)
    }

    const agregar = (estado_completo) => {
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.hitos.push(estado_completo)
            actualizarLineaTiempo(actual)
            return {...{}, ...actual}
        })
    }

    const estilo_icono_check = {
     color: "green"
    }

    const detectarEstadosActuales = () => {
        const mostrar_estados = lineaTiempoSeleccionada ? estados.filter(e => e.tipos_servicio.includes(tipoServicioSeleccionado?._id)).filter(e => {
            const ids_seleccionados = lineaTiempoSeleccionada.hitos.map(h => h._id)
            return !ids_seleccionados.includes(e._id)
        }) : estados.filter(e => e.tipos_servicio.includes(tipoServicioSeleccionado?._id))

        return mostrar_estados
    }
    
    const mostrarEstados = () => {
        if(loadingEstados) return <div>
            <Spinner animation="border" />
            <h4 level={5} className="mt-0">Cargando...</h4> </div>

        if(!tipoServicioSeleccionado) return <div>
            <h4 className="mt-0" level={5}>Selecciona un tipo de servicio para ver los estados asociados</h4>
        </div>

        if(estados.length < 1) return <div>
            <h4 className="mt-0" level={5}>El servicio que seleccionaste aún no tiene eventos logísticos asociados dentro del protocólo de entrega</h4>
            <Link to={rutas.estados_carga.slug}><Button >CREAR Y ASOCIAR EVENTOS</Button></Link>
        </div>

        const mostrar_estados = detectarEstadosActuales()

        if(mostrar_estados.length < 1) return <div>
            <h4 className="mt-0" level={5}>No hay más estados disponibles para agregar a la línea de tiempo</h4>
        </div>

        return <div>
            <h4 level={3} className="mt-0 mb-0">{mostrar_estados.length} Estados encontrados</h4>
            {/* <Alert className="mb-3" message={} type="info" /> */}
            <div><HiCursorClick /> A continuación toca el estado para agregarlo a la línea de tiempo</div>
            {
                mostrar_estados.map((estado, index) => {
                    return <div key={`estado-${index}`}>
                        <OverlayTrigger
                            key={estado._id}
                            // trigger="click"
                            overlay={
                                <Tooltip id={'right'}>Toca para agregar a la línea de tiempo</Tooltip>
                            }>
                            <Button level={5} icon={<HiCursorClick />} variant="light" className="mb-3 w-100 " onClick={() => agregar(estado)} >{estado.titulo}</Button>
                        </OverlayTrigger>
                        
                        
                    </div>
                })
            }
        </div>
    }

    const remover = (i) => {
        setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.hitos.splice(i, 1)
            actualizarLineaTiempo(actual)
            return {...{}, ...actual}
        })
    }

    const reordenarHitos = (hitos) => {
        setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.hitos = hitos
            actualizarLineaTiempo(actual)
            return {...{}, ...actual}
        })
    }

    const mostrarSubEstados = (estado) => {
        if(!estado) return false
        return estado.subestados.map((subestado, index) => {
            if(!subestado.email && !subestado.whatsapp) return false
            return <div key={`subestado-${(index+1)}`}>
            <h6 className="mt-0 mb-0">{subestado?.titulo}</h6>
            { subestado?.email ? <Badge variant="dark" className="mr-2"><FaRegEnvelope  /> Envia email </Badge> : false}
            { subestado?.whatsapp ? <Badge variant="success" className="mr-2"><FaWhatsapp /> Envia whatsapp </Badge> : false }
</div>})
    }

    const mostrarLineaTiempo = () => {

        if(loadingLineasTiempo) return <Spinner animation="border" />

        const tipos = tiposServicio.map(tipo => {
            tipo.lineas = lineasTiempo.filter(lt => lt.tipo_servicio === tipo._id)
            return tipo
        }).filter(e => e.lineas.length > 0)
        
        return <div>
            <ThemeProvider theme={theme}>
            <div>
            {
                tipos.map((tipo, index) => {
                    if(filter_serice_type) if(tipo.valor.toString().toLowerCase() !== filter_serice_type) return false
                    return <div key={`tipo-${tipo._id}`} className="mb-5">
                        <h5 style={{ textTransform: "uppercase" }}><b>{tipo.valor}</b></h5>
                        {
                                            tipo.lineas.map((linea,i) => {
                                                return <div key={`linea-${linea._id}`}>
                                                    <Card className="shadow p-3">
                                                    <Stepper activeStep={linea.hitos.length} alternativeLabel>
                                                    {
                                                        linea.hitos.map((hito, index) => {
                                                            if(!hito.subestados) return false
                                                            let mostrar_titulo = hito.subestados.filter(e => e.email || e.whatsapp).length > 0
                                                            if(!mostrar_titulo) return false
                                                            return <Step md={4} key={`hito-${(index+1)}`}>
                                                                <StepLabel><div>
                                                                <h6 style={{ textTransform: "uppercase" }}><b>{(index+1)} {hito.titulo}</b></h6>
                                                                {mostrarSubEstados(hito)}
                                                                </div></StepLabel>
                                                            </Step>
                                                        })
                                                    }
                                                    </Stepper>
                                                    </Card>
                                                </div>
                                            })
                            
                        }
                    </div>
                })
            }
            </div>
            </ThemeProvider>
        </div>

    }

    const handleCancel = () => setShowModal(false)
    const mostrarModal = () => {

        return <Modal
            title="Esta este es un ejemplo de tu línea de tiempo" 
            show={showModal} 
            // onOk={handleOk} 
            onHide={handleCancel}
            // confirmLoading={loading}
        >
            <div></div>
            {/* <Steps
                current={lineaTiempoSeleccionada?.hitos.length - 1}
                style={{ marginTop: 20 }}
                items={lineaTiempoSeleccionada?.hitos.map( (estado,i) => {
                    return {
                        title: estado.titulo,
                        description: fechaUTCATexto(DateTime.now().plus({ minutes: (i+10) }).toUTC().toISO()),
                        // icon: <FiBox />
                    }
                })}
            /> */}
    </Modal>
    }
    
    const render = () => {
        return <div>
            {mostrarModal()}
            <div style={{ display: "none" }}><TiposServicioSelector onLoad={(todo) => setTiposServicio(todo)} defaultValue={tipoServicioSeleccionado} titulo="Tipo de servicio" condicion={{ tipo: "servicios" }} module="estadoscarga" key_label="title" key_value="_id" onChangeValue={(data) => seleccionarTipoServicio(data)} /></div>
            <Row gutter={15}>
                <Col md={12}>
                    {mostrarLineaTiempo()}
                </Col>
            </Row>
        </div>
    }

    if(display_full === true ){

        const renderizar = <div>
            <Card>{render()}</Card>
        </div>

        return <Structure component={renderizar} />
    } else {
        return render()
    }
}

export default LineaTiempoNotificaciones