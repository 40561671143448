import React, { useState} from 'react'
import { useSelector } from 'react-redux'
import { Card, Nav, Tab } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import ApiKeysListado from '../../subComponents/integraciones/apikeys/apikeys_listado'
import Webhooks from '../../subComponents/integraciones/webhooks/webhooks'
import WidgetsListado from '../../subComponents/integraciones/widgets/widgets_listado'
import PluginsListado from '../../subComponents/integraciones/plugins/plugins_listado'
import ApiLess from '../../subComponents/integraciones/apiless'
import FuncionesListado from '../../subComponents/integraciones/funciones/listado'
import CourriersListado from '../../subComponents/integraciones/courriers/listado'
import { useQuery } from '../../lib/helpers/router'
import HelperIntegraciones from '../../subComponents/general/helperIntegraciones'
import { default_proveedores } from '../../subComponents/proveedores_transporte/data'
import { gps_integrados, url_images } from '../../lib/global/data'
import { IoMdAlert } from 'react-icons/io'

const sistemas = [
  { logo: `${url_images}/integraciones/mandrill-logo.png`, pais: "CL" },
  { logo: `${url_images}/integraciones/twilio.png`, pais: "CL" },
  { logo: `${url_images}/integraciones/whatsapp.png`, pais: "CL" },
  { logo: `${url_images}/integraciones/odoo.png`, pais: "CL" },
  { logo: `${url_images}/integraciones/sap.png`, pais: "CL" },
  { logo: `${url_images}/integraciones/bsale.png`, pais: "CL" },
  { logo: `${url_images}/integraciones/skualo.png`, pais: "CL" },
  { logo: `${url_images}/integraciones/hubspot.png`, pais: "CL" },
  { logo: `${url_images}/integraciones/laudus.png`, pais: "CL" },
  { logo: `${url_images}/integraciones/defontana.png`, pais: "CL" },
  { logo: `${url_images}/integraciones/docuware.png`, pais: "CL" },
]

const marketplaces = [
  {
    logo: `${url_images}/woocommerce.png`,
    pais: "CL"
  },
  {
    logo: `${url_images}/marketplaces/magento.png`,
    pais: "CL"
  },
  {
    logo: `${url_images}/marketplaces/jumpseller.png`,
    pais: "CL"
  },
  {
    logo: `${url_images}/marketplaces/shopify.png`,
    pais: "CL"
  },
  {
    logo: `${url_images}/marketplaces/prestashop.png`,
    pais: "CL"
  }
]

const Integraciones = (props) => {  

    const user = useSelector(state => state.miusuario)
    const location = useLocation()
    const [ selectedTab, setSelectedTab ] = useState('')
    const [ pais, setPais ] = useState('CL')
    const navigate = useNavigate();
    const query = useQuery()
    let default_key = 'apikeys'
    if(query.get("tab")) default_key = query.get("tab")

    const handleChangeTab = (e) => {
      const newTab = e;
      const queryParams = new URLSearchParams();
      queryParams.set('tab', newTab);
      navigate({
        pathname: location.pathname,
        search: `?${queryParams.toString()}`,
      });
      return setSelectedTab(newTab);
    }

    const datosVacios = (data) => {
      const info = <h5><IoMdAlert /> Sin datos para mostrar</h5>
      if(!data) return info
      if(!Array.isArray(data)) return info
      if(data.length === 0) return info
      return false
    }

    const mostrarOperadoresLogisticos = () => {
      return <Card className='p-3 mb-3'>
        <h5>Proveedores logísticos</h5>
        <hr className='mt-1 mb-3' />
          {datosVacios(default_proveedores.filter(e => e.pais === pais))}
          {/* {default_proveedores.map((proveedor, index) => <div style={{ height: 30, width: 130, backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></div>)} */}
          <Row>{default_proveedores.filter(e => e.pais === pais).map((proveedor, index) => <Col className='mb-3' md={2} style={{ height: 30, width: "40%", backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></Col>)}</Row>
      </Card>
    }
    
    const mostrarGPS = () => {
      return <Card className='p-3 mb-3'>
        <h5>GPS Integrados</h5>
        <hr className='mt-1 mb-3' />
          {datosVacios(gps_integrados.filter(e => e.pais === pais))}
          {/* {default_proveedores.map((proveedor, index) => <div style={{ height: 30, width: 130, backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></div>)} */}
          <Row>{gps_integrados.filter(e => e.pais === pais).map((proveedor, index) => <Col className='mb-3' md={2} style={{ height: 30, width: "40%", backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></Col>)}</Row>
      </Card>
    }

    const mostrarVarios = () => {
      return <Card className='p-3 mb-3'>
        <h5>Otros sistemas</h5>
        <hr className='mt-1 mb-3' />
          {datosVacios(sistemas.filter(e => e.pais === pais))}
          {/* {default_proveedores.map((proveedor, index) => <div style={{ height: 30, width: 130, backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></div>)} */}
          <Row>{sistemas.filter(e => e.pais === pais).map((proveedor, index) => <Col className='mb-3' md={2} style={{ height: 30, width: "40%", backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></Col>)}</Row>
      </Card>
    }
    
    const mostrarMarketplaces = () => {
      return <Card className='p-3 mb-3'>
        <h5>Ecommerce CMS</h5>
        <hr className='mt-1 mb-3' />
        {datosVacios(marketplaces.filter(e => e.pais === pais))}
          {/* {default_proveedores.map((proveedor, index) => <div style={{ height: 30, width: 130, backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></div>)} */}
          <Row>{marketplaces.filter(e => e.pais === pais).map((proveedor, index) => <Col className='mb-3' md={2} style={{ height: 30, width: "40%", backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></Col>)}</Row>
      </Card>
    }

    const handleChangePais = (e) => {
      const { value } = e.target
      setPais(value)
    }

      return <div className="fluid">
        <h1 className="h2">Integraciones</h1> 
        <select className="form-control mb-3" onChange={handleChangePais}>
          <option value="CL">Chile</option>
          <option value="PE">Perú</option>
        </select>

        {mostrarOperadoresLogisticos()}
        {mostrarMarketplaces()}
        {mostrarGPS()}
        {mostrarVarios()}

        <Tab.Container id="left-tabs-example" defaultActiveKey={default_key} >
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column" onSelect={handleChangeTab}>
            <Nav.Item><Nav.Link eventKey="apikeys">Llaves de API</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="webhooks">Webhooks</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="widgets">Tracking embedido</Nav.Link></Nav.Item>
            {/* <Nav.Item><Nav.Link eventKey="functions">Funciones</Nav.Link></Nav.Item> */}
            <Nav.Item><Nav.Link eventKey="apiless">Conexión sin API</Nav.Link></Nav.Item>
            {/* <Nav.Item><Nav.Link eventKey="plugins">Plugins ecommerce</Nav.Link></Nav.Item> */}
            {/* <Nav.Item><Nav.Link eventKey="courriers">Courriers</Nav.Link></Nav.Item> */}
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
          <Tab.Pane eventKey="apikeys"  >
                <HelperIntegraciones tipo="apikeys" style="modal" />
                <ApiKeysListado trash={true} token={user.tokenSession} condicion_default={{ status: 'trash' }} />
            </Tab.Pane>
            <Tab.Pane eventKey="webhooks">
                <HelperIntegraciones tipo="webhooks" style="modal" />
                <Webhooks token={user.tokenSession} />
            </Tab.Pane>
            <Tab.Pane eventKey="apiless">
                <HelperIntegraciones tipo="apiless" style="modal" />
                <ApiLess token={user.tokenSession} />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="functions">
                <HelperIntegraciones tipo="functions" style="modal" />
                <FuncionesListado token={user.tokenSession} />
            </Tab.Pane> */}
            <Tab.Pane eventKey="widgets">
                <HelperIntegraciones tipo="widgets" style="modal" />
                <WidgetsListado token={user.tokenSession} />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="plugins">
                <HelperIntegraciones tipo="plugins" style="modal" />
                <PluginsListado token={user.tokenSession} />
            </Tab.Pane> */}
            {/* <Tab.Pane eventKey="courriers">
                <HelperIntegraciones tipo="courriers" style="modal" />
                <CourriersListado />
            </Tab.Pane> */}
          </Tab.Content>
        </Col>
        </Row>
      </Tab.Container>
    </div>

}

export default Integraciones