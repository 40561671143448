import { useEffect, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { BsLayoutThreeColumns } from "react-icons/bs"
import { useSelector } from "react-redux"
import { modificarEsquema } from '../../redux/actions/esquemaTablas'
import { ReactSortable } from "react-sortablejs";
import { Esquema } from "../../lib/esquemas/tablas"

const ColumnasOrdenes = (props) => {
    const {
        defaultSchema,
        screen,
        onSave,
        key
    } = props
    const esquema_tablas = defaultSchema ? defaultSchema : Esquema.ORDENES.map(e => ({...e, active: true}))
    const [ esquemaInicial, setEsquemaInicial ] = useState(esquema_tablas)
    const [ esquemaState, setEsquemaState ] = useState(esquemaInicial)
    const [ pendingSave, setPendingSave ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const llave = key ? key : 'esquema'

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChange = (e, column) => {
        
        setEsquemaState(prev => {
            const actual = [...prev]
            const i = actual.findIndex(e => e.key === column.key)
            console.log(actual, { i })
            if(i > -1){
                actual[i].active = e.target.checked
            }
            return actual
        })
    }

    const refrescarOrden = () => {
        setPendingSave(false)
        setEsquemaInicial(esquemaState)
        setOpenModal(false)
        if(onSave) onSave(esquemaState)
    }
    const handleSort = (newList) => {
        setEsquemaState(newList)
        setPendingSave(true)
    }

    const abrirModal = () => {
        setEsquemaState(esquema_tablas)
        setOpenModal(true)
    }
    return <div key={llave}>
        <Button size="sm" className='w-100' variant='light' onClick={() => abrirModal(true)} ><BsLayoutThreeColumns /> COLUMNAS</Button>
        <Modal show={openModal} onHide={handleClose}
        centered
        >
        <Modal.Header closeButton className="pb-0">
        <div>
          <Modal.Title>Personaliza las columnas que deseas visualizar</Modal.Title>
          <p>Puedes mover los elementos para cambiar su posicion</p>
          </div>
        </Modal.Header>
        <Modal.Body>
            <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
            <ReactSortable list={esquemaState} setList={(d) => handleSort(d)}>
                          {esquemaState.map((column, i) => {
                            return (
                                <Col key={`column-${i}`} className="mb-3">
                                <Form.Group className="mb-0" controlId={column.key}>
                                  <div key={`columna-${i}`} className="handle hover d-flex bg-light font align-items-center border px-2 shadow-sm"
                                  style={{fontSize:15}}>
                                    <i className="fa-solid fa-arrows-up-down-left-right mr-1  text-secondary"></i>
                                    <Form.Check 
                                      type="switch" 
                                      checked={column.active === true} 
                                      name={column.key} 
                                      label={column.label} 
                                      onChange={(e) => handleChange(e, column)} />
                                  </div>
                                </Form.Group>
                              </Col>
                            );
                        })}
                        </ReactSortable>
                        </div>
            <div className="d-flex justify-content-end">
            <Button className="mt-3 w-100" variant="success" onClick={() => refrescarOrden()}>GUARDAR</Button>
            </div>
        </Modal.Body>
        
      </Modal>
    </div>
}

export default ColumnasOrdenes